import { render, staticRenderFns } from "./Customers.html?vue&type=template&id=7e9c5154&scoped=true&external"
import script from "./Customers.js?vue&type=script&lang=js&external"
export * from "./Customers.js?vue&type=script&lang=js&external"
import style0 from "./Customers.scss?vue&type=style&index=0&id=7e9c5154&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9c5154",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FWorkplan%2FCustomers%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports