export default {
    name: 'CustomersWp',

    components: {
    },

    data() {
        return {
        }
    },

    sockets: {
    },

    computed: {
    },

    methods: {
    },

    async mounted() {
    },

    filters: {
    },

    watch: {
    }
}